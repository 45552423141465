import { fileCategory, dropdownCategory } from '../../../common/const'

const generalTab = [
  { value: 'company', label: 'VEHICLE_COMMON_COMPANY_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.company
  { value: 'department', label: 'VEHICLE_COMMON_DEPARTMENT_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.department
  { value: 'businessUnit', label: 'VEHICLE_COMMON_BUSINESS-UNIT_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.businessUnit
  { value: 'branchOffice', label: 'VEHICLE_COMMON_BRANCH-OFFICE_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.branchOffice
  { value: 'branch', label: 'VEHICLE_COMMON_BRANCH_LABEL', type: 'select', maxLength: '', required: true, options: [] }, // organization.branch
  { value: 'licensePlateNumber', label: 'FORKLIFT_COMMON_LICENSE-PLATE_LABEL', type: 'text', maxLength: '255', required: false, subItems: [{ value: 'licensePlateAddress', label: 'VEHICLE_COMMON_LICENSE-PLATE-ADDRESS_LABEL', type: 'text', maxLength: '255' }, { value: 'licensePlateClass', label: 'VEHICLE_COMMON_LICENSE-PLATE-CLASS-NO_LABEL', type: 'text', maxLength: '255' }, { value: 'licensePlateHiragana', label: 'VEHICLE_COMMON_LICENSE-PLATE-HIRAGANA_LABEL', type: 'text', maxLength: '255' }, { value: 'licensePlateNumber', label: 'VEHICLE_COMMON_LICENSE-PLATE-NUMBER_LABEL', type: 'text', maxLength: '9' }] },
  { value: 'carNumber', label: 'FORKLIFT_COMMON_CARNUMBER_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'code', label: 'FORKLIFT_COMMON_CODE_LABEL', type: 'text', maxLength: '255', required: true },
  { value: 'manufacturer', label: 'FORKLIFT_COMMON_MANUFACTURER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.manufacturer },
  { value: 'bodyShape', label: 'VEHICLE_COMMON_BODY-SHAPE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.bodyShape_Forklift },
  { value: 'model', label: 'VEHICLE_COMMON_MODEL_LABEL', type: 'text', maxLength: '255', required: true },
  { value: 'power', label: 'FORKLIFT_COMMON_POWER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.power },
  { value: 'batteryVoltage', label: 'FORKLIFT_COMMON_BATTERYVOLTAGE_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.batteryVoltage },
  { value: 'status', label: 'FORKLIFT_COMMON_STATUS_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.status_Forklift }
]

const registrationTab = [
  { value: 'owner', label: 'FORKLIFT_COMMON_OWNER_LABEL', type: 'select', maxLength: '255', required: true, options: dropdownCategory.owner },
  { value: 'user', label: 'FORKLIFT_COMMON_USER_LABEL', type: 'select', maxLength: '255', required: true, options: dropdownCategory.user_Forklift },
  { value: 'userAddress', label: 'FORKLIFT_COMMON_USERADDRESS_LABEL', type: 'text', maxLength: '255', required: false },
  { value: 'purchaseDate', label: 'FORKLIFT_COMMON_PURCHASEDATE_LABEL', type: 'month', maxLength: '', required: true },
  { value: 'expensesIncludingLease', label: 'FORKLIFT_COMMON_EXPENSESINCLUDINGLEASE_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.expensesIncludingLease_Forklift },
  { value: 'leaseEndDate', label: 'FORKLIFT_COMMON_LEASEENDDATE_LABEL', type: 'date', maxLength: '', required: false, addMaxDate: 3650 },
  { value: 'leaseFee', label: 'FORKLIFT_COMMON_LEASEFEE_LABEL', type: 'number', maxLength: '9', required: false },
  { value: 'automobileTax', label: 'FORKLIFT_COMMON_AUTOMOBILETAX_LABEL', type: 'number', maxLength: '9', required: false },
  { value: 'weightTax', label: 'FORKLIFT_COMMON_WEIGHTTAX_LABEL', type: 'number', maxLength: '9', required: false },
  { value: 'inspectionExpireDate', label: 'FORKLIFT_COMMON_INSPECTIONEXPIREDATE_LABEL', type: 'date', maxLength: '', required: false, addMaxDate: 1096 },
  { value: 'annualInspectionDate', label: 'FORKLIFT_COMMON_ANNUALINSPECTIONDATE_LABEL', type: 'date', maxLength: '', required: true, addMaxDate: 61 },
  { value: 'holdingFormat', label: 'FORKLIFT_COMMON_HOLDINGFORMAT_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.holdingFormat },
  { value: 'mandatoryInsuranceEndDate', label: 'FORKLIFT_COMMON_MANDATORYINSURANCEENDDATE_LABEL', type: 'date', maxLength: '', required: false, addMaxDate: 1188 },
  { value: 'voluntaryInsuranceType', label: 'FORKLIFT_COMMON_VOLUNTARYINSURANCETYPE_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.voluntaryInsuranceType_Forklift },
  { value: 'voluntaryInsuranceEndDate', label: 'FORKLIFT_COMMON_VOLUNTARYINSURANCEENDDATE_LABEL', type: 'date', maxLength: '', required: false, addMaxDate: 3650 },
  { value: 'rentalEndDate', label: 'FORKLIFT_COMMON_RENTALENDDATE_LABEL', type: 'date', maxLength: '', required: false, addMaxDate: 3650 },
  { value: 'rentalFee', label: 'FORKLIFT_COMMON_RENTALFEE_LABEL', type: 'number', maxLength: '9', required: false }
]

const weightSizeInfoTab = [
  { value: 'vehicleWeight', label: 'FORKLIFT_COMMON_VEHICLEWEIGHT_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'maximumLiftHeight', label: 'FORKLIFT_COMMON_MAXIMUMLIFTHEIGHT_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'maximumLoad', label: 'FORKLIFT_COMMON_MAXIMUMLOAD_LABEL', type: 'number', maxLength: '15', required: true },
  { value: 'clawLength', label: 'FORKLIFT_COMMON_CLAWLENGTH_LABEL', type: 'number', maxLength: '15', required: true }
]

const detailedVehicleInfoTab = [
  { value: 'dashcamManufacturer', label: 'FORKLIFT_COMMON_DASHCAMMANUFACTURER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.dashcamManufacturer_Forklift },
  { value: 'rearLightingManufacturer', label: 'FORKLIFT_COMMON_REARLIGHTINGMANUFACTURER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.rearLightingManufacturer },
  { value: 'horizontalLaserManufacturer', label: 'FORKLIFT_COMMON_HORIZONTALLASERMANUFACTURER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.horizontalLaserManufacturer },
  { value: 'sensorsManufacturer', label: 'FORKLIFT_COMMON_SENSORSMANUFACTURER_LABEL', type: 'select', maxLength: '', required: true, options: dropdownCategory.sensorsManufacturer },
  { value: 'attachment1', label: 'FORKLIFT_COMMON_ATTACHMENT1_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.attachment },
  { value: 'attachment2', label: 'FORKLIFT_COMMON_ATTACHMENT2_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.attachment },
  { value: 'attachment3', label: 'FORKLIFT_COMMON_ATTACHMENT3_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.attachment },
  { value: 'attachment4', label: 'FORKLIFT_COMMON_ATTACHMENT4_LABEL', type: 'select', maxLength: '', required: false, options: dropdownCategory.attachment }
]

export const tabConstants = {
  generalTab: { title: 'VEHICLE_COMMON_GENERAL-INFO_LABEL', limit: 5, maxItemEachCol: 7, fileSize: 5 },
  registrationTab: { title: 'VEHICLE_COMMON_REGISTRATION-INFO_LABEL', limit: 1, maxItemEachCol: 9, fileSize: 2 },
  weightSizeInfoTab: { title: 'VEHICLE_COMMON_WEIGHT-SIZE-INFO_LABEL', maxItemEachCol: 2 },
  detailedVehicleInfo: { title: 'VEHICLE_COMMON_DETAILED-VEHICLE-INFO_LABEL', maxItemEachCol: 4 }
}

export const labelName = {
  generalTab,
  registrationTab,
  weightSizeInfoTab,
  detailedVehicleInfoTab
}

export const dialogMessageList = [
  { type: 'info', code: 'save', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-SAVE_LABEL' },
  { type: 'info', code: 'remove_file', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-REMOVE-FILE_LABEL' },
  { type: 'info', code: 'delete_vehicle', message: 'FORKLIFT_FORKLIFT-EDIT_POPUP-DELETE-VEHICLE_LABEL' },
  { type: 'info', code: 'cancel_create_vehicle', message: 'FORKLIFT_FORKLIFT-CREATE_POPUP-CANCEL-CREATE_LABEL' },
  { type: 'info', code: 'cancel_edit_vehicle', message: 'VEHICLE_VEHICLE-EDIT_POPUP-CANCEL-EDIT_LABEL' },
  { type: 'error', code: 'id_exist', message: 'FORKLIFT_FORKLIFT-CREATE-EDIT_POPUP-DUPLICATE_LABEL' },
  { type: 'error', code: 'has_co2_report', message: 'VEHICLE_VEHICLE-EDIT_POPUP-CANNOT-DELETE_LABEL' },
  { type: 'error', code: 'wrong_file_type', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-WRONG-FILE-TYPE_LABEL' },
  { type: 'error', code: 'max_file_num', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-OVER-MAX-FILE-NO_LABEL' },
  { type: 'error', code: 'max_file_size', message: 'VEHICLE_VEHICLE-CREATE-EDIT_POPUP-OVER-MAX-FILE-SIZE_LABEL' },
  { type: 'error', code: 'dropdown_not_exist', message: 'COMMON_DROPDOWN-NOT-EXIST_MESSAGE_LABEL' },
  { type: 'error', code: 'unknown', message: 'COMMON_UNKNOWN-ERROR_MESSAGE_LABEL' }
]

export const requiredItems = [
  'company',
  'branch',
  'code',
  'status',
  'purchaseDate',
  'manufacturer',
  'model',
  'bodyShape',
  'power',
  'maximumLoad',
  'vehicleWeight',
  'maximumLiftHeight',
  'clawLength',
  'holdingFormat',
  'owner',
  'user',
  'annualInspectionDate',
  'expensesIncludingLease',
  'dashcamManufacturer',
  'horizontalLaserManufacturer',
  'rearLightingManufacturer',
  'sensorsManufacturer'
]

export const dropdownKey = {
  status: dropdownCategory.status_Forklift,
  manufacturer: dropdownCategory.manufacturer,
  bodyShape: dropdownCategory.bodyShape_Forklift,
  power: dropdownCategory.power,
  batteryVoltage: dropdownCategory.batteryVoltage,
  owner: dropdownCategory.owner,
  user: dropdownCategory.user_Forklift,
  voluntaryInsuranceType: dropdownCategory.voluntaryInsuranceType_Forklift,
  holdingFormat: dropdownCategory.holdingFormat,
  expensesIncludingLease: dropdownCategory.expensesIncludingLease_Forklift,
  dashcamManufacturer: dropdownCategory.dashcamManufacturer_Forklift,
  rearLightingManufacturer: dropdownCategory.rearLightingManufacturer,
  horizontalLaserManufacturer: dropdownCategory.horizontalLaserManufacturer,
  sensorsManufacturer: dropdownCategory.sensorsManufacturer,
  attachment: dropdownCategory.attachment
}

export const fileTypeList = {
  Syaken: fileCategory.Syaken,
  Zibaiseki: fileCategory.Zibaiseki,
  Syaryou: fileCategory.Syaryou,
  NenziTenkin: fileCategory.NenziTenkin,
  KounyuziKeiyaku: fileCategory.KounyuziKeiyaku,
  Rental: fileCategory.Rental,
  NiniHoken: fileCategory.NiniHoken
}
